import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  zendeskId: "cab743de-0681-4851-9630-98da2a6e3d80",
  apiUrl: "https://api-cimoagro-homolog.graodireto.com.br",
  logo: "graodireto",
  appTitle: "Barter Fácil",
  theme: "graodireto",
  companyLanguage: "default",
  companyName: "Cimoagro",
  pdfUrl: "http://localhost:4206/assets",
  keyCloakConfig: {
    url: "https://auth-homolog.graodireto.com.br",
    realm: "cimoagro",
    clientId: "cimoagro-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyClggNi_Cl9meYCXocmYx0kVJPI20SLLT0",
    authDomain: "barter-cimoagro.firebaseapp.com",
    projectId: "barter-cimoagro",
    storageBucket: "barter-cimoagro.appspot.com",
    messagingSenderId: "113811071479",
    appId: "1:113811071479:web:d47332f512d8957b1c0ef2",
    measurementId: "G-DLMK84F1SS",
  },
  hotjarConfig: "4946547",
  nps: {
    api: "https://api-homolog.graodireto.com.br/api",
    url: "https://graodireto-site-homolog.graodireto.com.br/nps",
    key: "4b51b41f-bf44-44db-877f-90f90ed9b7b8",
  },
  npsSourceId: NpsEnum.EASY_BARTER_CIMOAGRO,
});
